import { XF } from "./XF";

const listingFiltersForm = $("#listing-filter-form");

// if searching from marketplace home, redirect the search form submit to the listing filter form instead
if (
  document.documentElement.dataset.template === "california_marketplace_home"
) {
  $(".search-bar").on("submit", (e) => {
    e.preventDefault();
    listingFiltersForm.trigger("submit");
  });
}

const params = new URL(document.location.href).searchParams;
if (params.get("listingDeleted") === "1") {
  XF.toast("Your listing has been removed.");
}
